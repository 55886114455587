import breakpoints from "@/breakpoints";
import * as fonts from "@/fonts";
import { alpha, createTheme } from "@mui/material";
import { Link as I18NLink } from "gatsby-plugin-intl";
import { forwardRef } from "react";

const LinkBehaviour = forwardRef(function LinkBehaviour(props, ref) {
  return <I18NLink ref={ref} {...props} />;
});

export const blue = {
  50: "hsl(210, 100%, 96%)",
  100: "hsl(210, 100%, 90%)",
  200: "hsl(210, 100%, 80%)",
  300: "hsl(210, 100%, 70%)",
  400: "hsl(210, 100%, 60%)",
  main: "hsl(210, 100%, 45%)",
  500: "hsl(210, 100%, 45%)",
  600: "hsl(210, 100%, 42%)",
  700: "hsl(210, 100%, 38%)",
  800: "hsl(210, 100%, 30%)",
  900: "hsl(210, 100%, 23%)",
};
export const blueDark = {
  50: "hsl(210, 14%, 92%)",
  100: "hsl(210, 14%, 87%)",
  200: "hsl(210, 14%, 72%)",
  300: "hsl(210, 14%, 56%)",
  main: "hsl(210, 14%, 56%)",
  400: "hsl(210, 14%, 36%)",
  500: "hsl(210, 14%, 28%)",
  600: "hsl(210, 14%, 22%)",
  700: "hsl(210, 14%, 13%)",
  800: "hsl(210, 14%, 9%)",
  900: "hsl(210, 14%, 7%)",
};
export const grey = {
  50: "hsl(215, 15%, 97%)",
  100: "hsl(215, 15%, 92%)",
  200: "hsl(215, 15%, 89%)",
  300: "hsl(215, 15%, 82%)",
  400: "hsl(215, 15%, 75%)",
  500: "hsl(215, 15%, 65%)",
  600: "hsl(215, 15%, 50%)",
  700: "hsl(215, 15%, 40%)",
  800: "hsl(215, 15%, 22%)",
  900: "hsl(215, 15%, 12%)",
};
export const error = {
  50: "hsl(355, 98%, 97%)",
  100: "hsl(355, 98%, 93%)",
  200: "hsl(355, 98%, 87%)",
  300: "hsl(355, 98%, 80%)",
  400: "hsl(355, 98%, 74%)",
  500: "hsl(355, 98%, 66%)",
  main: "hsl(355, 98%, 66%)",
  600: "hsl(355, 98%, 46%)",
  700: "hsl(355, 98%, 39%)",
  800: "hsl(355, 98%, 29%)",
  900: "hsl(355, 98%, 17%)",
};
export const success = {
  50: "hsl(144, 72%, 95%)",
  100: "hsl(144, 72%, 87%)",
  200: "hsl(144, 72%, 77%)",
  300: "hsl(144, 72%, 66%)",
  400: "hsl(144, 72%, 56%)",
  500: "hsl(144, 72%, 46%)",
  600: "hsl(144, 72%, 41%)",
  700: "hsl(144, 72%, 37%)",
  800: "hsl(144, 72%, 32%)",
  900: "hsl(144, 72%, 21%)",
};
export const warning = {
  50: "hsl(48, 100%, 96%)",
  100: "hsl(48, 100%, 88%)",
  200: "hsl(48, 100%, 82%)",
  300: "hsl(48, 100%, 64%)",
  400: "hsl(48, 100%, 48%)",
  500: "hsl(48, 100%, 44%)",
  main: "hsl(48, 100%, 44%)",
  600: "hsl(40, 100%, 40%)",
  700: "hsl(36, 100%, 34%)",
  800: "hsl(36, 100%, 27%)",
  900: "hsl(36, 100%, 18%)",
};

const defaultTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const theme = createTheme({
  breakpoints: {
    values: breakpoints,
  },
  typography: {
    fontFamily: fonts.MONTSERRAT,
    "section-title": {
      fontWeight: "700",
      fontFamily: fonts.IBM_PLEX_SANS,
      fontSize: "16px",
      color: blue[400],
    },
    title: {
      color: "hsl(215, 15%, 92%)",
      fontFamily: fonts.ROBOTO,
      fontWeight: 600,
      letterSpacing: "-0.2px",
      fontSize: { sx: "19px", sm: "24px", md: "28px" },
    },
  },
  palette: {
    mode: "dark",
    primary: {
      ...blue,
      ...{
        main: blue[400],
      },
    },
    secondary: {
      ...grey,
      ...{
        main: blueDark[700],
        contrastText: blueDark[600],
      },
    },
    divider: alpha(blueDark[500], 0.3),
    primaryDark: blueDark,
    ...{
      background: {
        default: blueDark[900],
        paper: alpha(blueDark[800], 0.8),
      },
    },
    common: {
      black: "hsl(200, 10%, 4%)",
    },
    text: {
      ...{
        primary: "#fff",
        secondary: grey[400],
        tertiary: grey[500],
      },
    },
    grey: {
      ...grey,
      ...{
        main: grey[700],
        contrastText: grey[600],
      },
    },
    error,
    success: {
      ...success,
      ...{
        main: success[600],
      },
    },
    warning,
    gradients: {
      radioSubtle: `radial-gradient(100% 100% at 100% 100%, transparent 0, ${alpha(blue[900], 0.3)} 300%)`,
      linearSubtle: `linear-gradient(to bottom right, ${alpha(blue[900], 0.1)} 25%, ${alpha(blueDark[800], 0.2)} 100%)`,
    },
    shape: {
      borderRadius: 12,
    },
    spacing: 8,

    // typography: {
    //   fontFamily: ['"IBM Plex Sans"', ...systemFont].join(','),
    //   // Match VS Code
    //   // https://github.com/microsoft/vscode/blob/b38691f611d1ce3ef437c67a1b047c757b7b4e53/src/vs/editor/common/config/editorOptions.ts#L4578-L4580
    //   // https://github.com/microsoft/vscode/blob/d950552131d7350a45dac8b59bf179469c36c2ac/src/vs/editor/standalone/browser/standalone-tokens.css#L10
    //   fontFamilyCode: [
    //     'Menlo', // macOS
    //     'Consolas', // Windows
    //     '"Droid Sans Mono"', // Linux
    //     'monospace', // fallback
    //   ].join(','),
    //   fontFamilyTagline: ['"General Sans"', ...systemFont].join(','),
    //   fontFamilySystem: systemFont.join(','),
    //   fontWeightSemiBold: 600,
    //   fontWeightExtraBold: 800,
    //   h1: {
    //     fontFamily: ['"General Sans"', ...systemFont].join(','),
    //     fontSize: 'clamp(2.5rem, 1.125rem + 3.5vw, 3.5em)',
    //     fontWeight: 600,
    //     lineHeight: 78 / 70,
    //     letterSpacing: -0.2,
    //     ...(mode === 'light' && {
    //       color: blueDark[900],
    //     }),
    //   },
    //   h2: {
    //     fontFamily: ['"General Sans"', ...systemFont].join(','),
    //     fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)',
    //     fontWeight: 600,
    //     lineHeight: 44 / 36,
    //     letterSpacing: -0.2,
    //     color: mode === 'dark' ? grey[100] : blueDark[700],
    //   },
    //   h3: {
    //     fontFamily: ['"General Sans"', ...systemFont].join(','),
    //     fontSize: defaultTheme.typography.pxToRem(36),
    //     lineHeight: 44 / 36,
    //     letterSpacing: 0.2,
    //   },
    //   h4: {
    //     fontFamily: ['"General Sans"', ...systemFont].join(','),
    //     fontSize: defaultTheme.typography.pxToRem(30),
    //     lineHeight: 42 / 28,
    //     letterSpacing: 0.2,
    //   },
    //   h5: {
    //     fontSize: defaultTheme.typography.pxToRem(24),
    //     lineHeight: 36 / 24,
    //     letterSpacing: 0.1,
    //     color: mode === 'dark' ? blue[300] : blue.main,
    //   },
    //   h6: {
    //     fontSize: defaultTheme.typography.pxToRem(20),
    //     lineHeight: 30 / 20,
    //   },
    //   button: {
    //     textTransform: 'initial',
    //     fontWeight: 700,
    //     letterSpacing: 0,
    //   },
    //   subtitle1: {
    //     fontSize: defaultTheme.typography.pxToRem(18),
    //     lineHeight: 24 / 18,
    //     letterSpacing: 0,
    //     fontWeight: 500,
    //   },
    //   body1: {
    //     fontSize: defaultTheme.typography.pxToRem(16), // 16px
    //     lineHeight: 24 / 16,
    //     letterSpacing: 0,
    //   },
    //   body2: {
    //     fontSize: defaultTheme.typography.pxToRem(14), // 14px
    //     lineHeight: 21 / 14,
    //     letterSpacing: 0,
    //   },
    //   caption: {
    //     display: 'inline-block',
    //     fontSize: defaultTheme.typography.pxToRem(12), // 12px
    //     lineHeight: 18 / 12,
    //     letterSpacing: 0,
    //     fontWeight: 700,
    //   },
    //   allVariants: {
    //     scrollMarginTop: 'calc(var(--MuiDocs-header-height) + 32px)',
    //   },
    // },

    // OLD STYLES HERE
    // background: {
    //   default: "hsl(210, 14%, 7%)",
    // },
    // text: {
    //   primary: defaultTheme.palette.grey["500"],
    // },

    text_grey: "rgb(152, 164, 179)",
    text_dark: "rgb(152, 164, 179)",
    trust_pilot: "#00B67A",
    text_blue: blue[400],
    blue: blue[400],
    white: "#fff",
    green: "#228B22",
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "hsla(210, 14%, 28%, 0.3)",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "hsla(210, 14%, 7%, 0.7)",
          backgroundImage: "none",
          boxShadow: "inset 0px -1px 1px hsl(210, 14%, 13%)",
          backdropFilter: "blur(8px)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "contained" &&
            ownerState.color === "blue" && {
              backgroundColor: blue.main,
              backgroundImage:
                "linear-gradient(180deg, hsla(210, 100%, 45%, 0.6) 0%, hsl(210, 100%, 42%) 100%)",
              boxShadow:
                "hsl(210, 100%, 60%) 0 2px 0 inset,hsl(210, 100%, 38%) 0 -2px 0 inset,hsla(200, 10%, 4%, 0.1) 0 2px 4px 0",
              textShadow: "0 1px 1px hsla(200, 10%, 4%, 0.6)",
              color: "#fff",
              fontWeight: 600,
              textTransform: "none", // border: "2px solid hsl(210, 100%, 45%)",

              "&:hover": {
                backgroundColor: "hsl(210, 100%, 45%)",
                backgroundImage:
                  "linear-gradient(180deg, hsla(210, 100%, 45%, 0.6) 0%, hsl(210, 100%, 42%) 100%)",
                boxShadow:
                  "hsl(210, 100%, 50%) 0 2px 0 inset,hsl(210, 100%, 30%) 0 -2px 0 inset,hsla(200, 10%, 4%, 0.1) 0 2px 4px 0",
              },
            }),
          ...(ownerState.variant === "contained" &&
            ownerState.color === "green" && {
              backgroundColor: "#228B22",
              backgroundImage:
                "linear-gradient(0deg, rgba(3,233,3,1) 0%, rgba(34,139,34,1) 8%)",
              border: "2px solid #0eb80e",
              boxShadow: "none",
              textShadow: "0 1px 1px hsla(200, 10%, 4%, 0.6)",
              color: "#fff",
              fontWeight: 600,
              textTransform: "none",

              "&:hover": {
                backgroundColor: "rgba(34,139,34,0.9)",
                backgroundImage:
                  "linear-gradient(0deg, rgba(3,233,3,1) 0%, rgba(34,139,34,1) 15%)",
                boxShadow: "none",
              },
            }),
        }),
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          fontFamily: fonts.ROBOTO,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: 0,
        }),
      },
      variants: [
        {
          props: { variant: "gradient-bottom-blue" },
          style: {
            background:
              "linear-gradient(hsl(210, 14%, 7%) 0%, hsla(210, 100%, 23%, 0.2) 100%)",
          },
        },
        {
          props: { variant: "darkblue" },
          style: {
            background: blueDark[800],
          },
        },
      ],
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          title: "h3",
          "section-title": "h5",
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehaviour,
      },
    },
    MuiListItemButton: {
      variants: [
        {
          props: { variant: "features-list-item" },
          style: {
            border: "1px solid transparent",
            borderRadius: "10px",
            paddingLeft: defaultTheme.spacing(2),
            paddingRight: defaultTheme.spacing(2),
            marginBottom: defaultTheme.spacing(1),

            "&:hover": {
              backgroundColor: "hsla(210, 100%, 23%, 0.1)",
              borderColor: "hsla(210, 100%, 30%, 0.4)",
            },

            "&.Mui-selected, &.Mui-selected:hover": {
              backgroundColor: "hsla(210, 100%, 30%, 0.2)",
              borderColor: "hsla(210, 100%, 38%, 0.8)",
              boxShadow:
                "hsla(200, 10%, 4%, 0.2) 0 -3px 1px inset,hsl(210, 14%, 7%) 0 2px 3px 0",
            },

            "& .MuiListItemIcon-root": {
              backgroundColor: "#132F4C",
              padding: "5px",
              borderRadius: "50%",
              minWidth: "auto",
              marginRight: defaultTheme.spacing(2),
            },
            "& .MuiListItemText-root": {
              // marginTop: 0,
              // marginBottom: 0,
            },
            "& .MuiListItemText-secondary": {
              fontSize: "13px",
            },
          },
        },
      ],
    },
  },
});

export default theme;
