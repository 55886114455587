const LOCAL_TG_START = "tg_start";
const LOCAL_TG_PARTNER = "tg_partner";

const PARAM_PARTNER_START = "invite_";
const PARAM_REFERRAL_START = "r_";

const isBrowser = () => typeof window !== "undefined";

export function telegramBotName() {
  if (!process.env.GATSBY_TELEGRAM_BOT) {
    throw new Error("GATSBY_TELEGRAM_BOT is not defined");
  }
  return process.env.GATSBY_TELEGRAM_BOT;
}

export function telegramBotUrl() {
  let tgStart =
    (isBrowser() && window?.localStorage?.getItem(LOCAL_TG_START)) || "";
  if (!!tgStart) {
    tgStart = `?start=${tgStart}`;
  }

  return `https://t.me/${telegramBotName()}${tgStart}`;
}

export function hasPartnerFlag() {
  if (!isBrowser()) {
    return false;
  }

  return window?.localStorage?.getItem(LOCAL_TG_PARTNER) === "true";
}

export function applyTelegramBotStartParams(queryParams) {
  const params = queryParams.filter((item) => !!item);

  if (!isBrowser() || !params || ![...params].length) {
    return;
  }

  const localStart = window.localStorage.getItem(LOCAL_TG_START) || "";
  let newLocalStart;
  let setFlagPartner = false;

  const partnerSignupParam = params.find(
    (item) =>
      item.startsWith(PARAM_PARTNER_START) &&
      item.length > PARAM_PARTNER_START.length + 1,
  );
  const referralSignupParam = params.find(
    (item) =>
      item.startsWith(PARAM_REFERRAL_START) &&
      item.length > PARAM_REFERRAL_START.length + 1,
  );

  if (!!partnerSignupParam) {
    newLocalStart = partnerSignupParam;
    setFlagPartner = true;
  } else if (!!referralSignupParam && !localStart) {
    newLocalStart = referralSignupParam;
  }

  if (!!newLocalStart) {
    window.localStorage.setItem(LOCAL_TG_START, newLocalStart);
  }

  if (!!setFlagPartner) {
    window.localStorage.setItem(LOCAL_TG_PARTNER, "true");
  }
}
