import theme from "@/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

export default function MUITheme({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
