import { cache } from "@/emotion";
import MUITheme from "@/layouts/MUITheme";
import { isDevelopmentBuild } from "@/utils";
import { applyTelegramBotStartParams } from "@/utils/telegram";
import { CacheProvider } from "@emotion/react";

export function wrapPageElement({ element }) {
  // we have to apply the theme in layout component to have hot reload working
  return !isDevelopmentBuild() ? element : <MUITheme>{element}</MUITheme>;
}

export function wrapRootElement({ element }) {
  // we have to apply the theme in layout component to have hot reload working
  return (
    <CacheProvider value={cache}>
      {isDevelopmentBuild() ? element : <MUITheme>{element}</MUITheme>}
    </CacheProvider>
  );
}

export function onPreRouteUpdate({ location, prevLocation }) {
  let params = [];

  if (((!!location && location.search) || "").trim() !== "") {
    params.push(location.search);
  }
  if (((!!prevLocation && prevLocation.search) || "").trim() !== "") {
    params.push(prevLocation.search);
  }

  params = params.map((item) => {
    const p = new URLSearchParams(item);
    if (!!p.get("s")) {
      return p.get("s");
    }

    return null;
  });

  applyTelegramBotStartParams(params);
}

export function onClientEntry() {
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }

  if (!(`polyfillCountryFlagEmojisInstalled` in window)) {
    window.polyfillCountryFlagEmojisInstalled = true;
    import(`country-flag-emoji-polyfill`).then((module) => {
      module.polyfillCountryFlagEmojis();
    });
  }
}
